@import url('https://fonts.googleapis.com/css?family=Montserrat:500');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Roboto&family=Work+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body {
    background: var(--primary-color-bg);
}

.theme-dark {
    --primary-color-text: #fefefe;
    --primary-color-bg: #171e30;
    --secondary-color-text: #2563EB;
    --secondary-color-bg: #1f283b;
    --third-color-bg: #fff;
    --green-color: lightgreen;
    --contact-color-bg: #282e3b;
    --inverse-color-text: #0c0c0c;

    --invert-svg: invert(1);
}

.theme-light {
    --primary-color-text: #0c0c0c;
    --primary-color-bg: #f6f7fc;
    --secondary-color-text: #1a91ff;
    --secondary-color-bg: #fffefe;
    --third-color-bg: #fff;
    --green-color: green;
    --contact-color-bg: #f3f2f7;
    --inverse-color-text: #fefefe;

    --invert-svg: invert(0);
}

header {
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--secondary-color-bg);
    padding: 20px 12%;
    transition: all 0.50s ease;
    font-family: 'Open Sans', sans-serif;
    font-family: 'Roboto', sans-serif;
}

.logo {
    display: flex;
    align-items: center;
}

.logo img {
    width: 31px;
    height: auto;
    margin-right: 3px;
}

.logo span {
    color: var(--primary-color-text);
    font-size: 1.3rem;
    font-weight: bold;
}

.navbar {
    display: flex;
}

.navbar a {
    color: var(--primary-color-text);
    padding: 5px;
    margin: 0 20px;
    transition: all 0.50s ease;
    font-family: 'Roboto', sans-serif;
    font-size: 1.01rem;
}

.navbar a:hover {
    color: var(--secondary-color-text);
}

.navbar a.active {
    color: var(--secondary-color-text);
}

.main-navbar {
    display: flex;
    align-items: center;
}

.main-navbar a {
    margin-right: 25px;
    margin-left: 10px;
    color: var(--primary-color-text);
    font-size: 1.1rem;
    font-weight: 500;
    transition: all 0.50s ease;
}

.main-navbar a:hover {
    color: var(--secondary-color-text);
}

.dark-toggle {
    display: flex;
    align-items: center;
    font-size: 48px;
    margin-right: 7px;
}

#nav-icon {
    font-size: 35px;
    color: var(--primary-color-text);
    cursor: pointer;
    z-index: 10001;
    display: none;
}

.line {
    width: 30px;
    height: 3px;
    background: var(--primary-color-text);
    margin: 6px 0;
}

/* Header */
.hero-section {
    text-align: center;
    padding: 5rem 0;
    margin-bottom: 2%;
    margin-top: 2%;
}

.hero-title {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    margin-top: 180px;
    color: var(--primary-color-text);
}

.hero-subtitle {
    font-size: 1.5rem;
    margin-bottom: 3rem;
    color: var(--primary-color-text);
    font-family: 'Roboto', sans-serif;
}

.button-solid {
    background-color: var(--secondary-color-text);
    color: #FFFFFF;
    border: none;
    padding: 14px 30px;
    margin-right: 10px;
    font-size: 1rem;
    border-radius: 0.25rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.button-solid:hover, .button-solid:focus, .button-solid:active {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform: scale(1.01);
}

.button-outline {
    background-color: transparent;
    color: var(--secondary-color-text);
    border: 2px solid var(--secondary-color-text);
    padding: 14px 30px;
    font-size: 1rem;
    border-radius: 0.25rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.button-outline:hover, .button-outline:focus, .button-outline:active {
    background-color: var(--secondary-color-text);
    color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform: scale(1.01);
}

/* Services */
.services-container {
    width: 100%;
    padding: 5% 8%;
    margin-bottom: 2%;
}

.services-container h1 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: var(--primary-color-text);
}

.services-row {
    display: grid;
    max-width: 1000px;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 30px;
}

.service {
    text-align: center;
    padding: 25px 10px;
    background-color: var(--secondary-color-bg);
    border-radius: 7px;
    font-size: 14px;
}

.service:hover {
    transform: scale(1.01);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    transition: 0.3s ease-out;
}

.service i {
    font-size: 40px;
    margin-bottom: 10px;
    color: var(--secondary-color-text);
}

.service-icon {
    font-size: 32px;
    margin-bottom: 10px;
    color: var(--secondary-color-text);
}

.service h2 {
    font-size: 18.5px;
    margin-bottom: 8px;
    color: var(--primary-color-text);
}

.service p {
    color: var(--primary-color-text);
    padding: 0 20px;
}

/* Pricing */
.container {
    width: 100%;
    padding-top: 3.5%;
    margin-top: 3.5%;
}

.container h2 {
    color: var(--primary-color-text);
    font-size: 2.5rem;
    padding: 50px 0;
    text-align: center;
}

.price-row {
    width: 90%;
    max-width: 1100px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 25px;
}

.price-col {
    background: var(--secondary-color-bg);
    padding: 10% 15%;
    border-radius: 10px;
    color: var(--primary-color-text);
    text-align: center;
    margin-top: 2%;
}

.center {
    background-color: var(--secondary-color-text);
    height: 105%;
    color: #fff;
    margin-top: -3%;
}

.price-col p {
    font-size: 20px;
}

.price-col h3 {
    font-size: 2.5rem;
    margin: 20px 0 40px;
    font-weight: 500;
}

.price-col h3 span {
    font-size: 16px;
}

.price-col ul {
    text-align: left;
    margin: 20px 0;
    color: var(--primary-color-text);
    list-style: none;
}

.price-col .last-point::before {
    text-decoration: none;
    list-style: none;
    list-style-type: none;
    color: var(--inverse-color-text);
}

.center .last-point::before {
    text-decoration: none;
    list-style: none;
    list-style-type: none;
    color: var(--secondary-color-text);
}

.price-col ul li {
    margin: 15px 0;
}

.center ul li {
    color: #fff;
}

.price-col ul li:before {
    content: '\2022';
    color: var(--secondary-color-text);
    font-weight: bold;
    margin-right: 8px;
}

.center ul li:before {
    content: '\2022';
    color: var(--primary-color-bg);
}

.price-col button {
    width: 100%;
    padding: 14px 0;
    background: transparent;
    color: var(--primary-color-text);
    font-size: 15px;
    border: 1px solid var(--secondary-color-text);
    border-radius: 6px;
    margin-top: 30px;
    cursor: pointer;
    transition: background 0.5s;
}

.center button {
    border: 1px solid var(--primary-color-bg);
    background: transparent;
    transition: background 0.5s;
}

.price-col button:hover {
    background: var(--secondary-color-text);
}

.center button:hover {
    background: var(--primary-color-bg);
}

/* Pricing v2 */
.new-pricing {
    background-color: var(--secondary-color-bg);
}

.new-pricing-container {
    max-width: 850px;
    margin: 0 auto;
    padding: 50px 20px;
}

.new-pricing-container h2 {
    color: var(--primary-color-text);
    font-size: 2.5rem;
    padding: 20px 0;
    text-align: center;
}

.new-pricing-description {
    text-align: center;
    margin: 0 0 40px 0;
    color: var(--primary-color-text);
}

.new-pricing-description {
    font-size: 18px;
}

.new-pricing-card-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 850px;
}

.new-pricing-card {
    display: flex;
    align-items: center;
    padding: 24px;
    background: var(--primary-color-bg);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    margin-bottom: 12px;
    max-width: 850px; 
}

.new-pricing-card:hover {
    transform: scale(1.007);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    transition: 0.3s ease-out;
}

.new-pricing-card-header {
    flex: 0 0 auto;
    margin-right: 24px;
}

.new-pricing-card-header span {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #3B82F6;
    display: inline-block;
    margin-right: 8px;
}

.new-pricing-card-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--primary-color-text);
}

.new-pricing-card-content {
    flex-grow: 1;
}

.new-pricing-card-subtitle {
    font-size: 17.5px;
    color: var(--primary-color-text);
    margin-bottom: 4px;
}

.new-pricing-card-text {
    font-size: 14px;
    color: var(--primary-color-text);
}

.new-pricing-card-button {
    flex: 0 0 auto;
    background: var(--secondary-color-text);
    color: var(--primary-color-text);
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: 24px;
}

.new-pricing-card-button:hover {
    background: #33a1ff;
}

/* Features */
.feature-container {
    width: 100%;
    margin: 4% auto;
    padding: 0 8%;
}

.feature-container h2 {
    color: var(--primary-color-text);
    font-size: 2.5rem;
    padding: 50px 0;
    text-align: center;
}

table {
    font-size: 17px;
    border-collapse: collapse;
    background-color: var(--primary-color-bg);
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
    margin-left: auto;
    margin-right: auto;
}

table:hover {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    transform: scale(1.002);
    transition: 0.1s ease-out;
}

td {
    padding: 25px 55px;
    text-align: center;
    color: var(--primary-color-text);
}

.checked {
    color: var(--green-color);
}

.unchecked {
    color: red;
}

th {
    padding: 25px 55px;
    text-align: left;
}

thead {
    background-color: var(--secondary-color-text);
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
}

th:first-child {
    border-radius: 10px 0 0 0;
}

th:last-child {
    border-radius: 0 10px 0 0;
}

tr:nth-child(even) {
    background-color: var(--secondary-color-bg);
}

.feature-container p {
    color: var(--primary-color-text);
    margin-top: 10px;
    text-align: center;
}

/* FAQ */
.faq {
    width: 80%;
    margin: 4.5% auto 3.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #cacaca;
}

.faq-title {
    font-size: 2.5rem;
    margin: 2rem 0rem;
    margin-bottom: 0px;
    color: var(--primary-color-text);
}

.faq-list {
    margin: 50px auto;
    width: 65%;
}

.faq-list li {
    list-style: none;
    width: 100%;
    margin-bottom: 10px;
    background: var(--secondary-color-bg);
    padding: 20px;
    border-radius: 4px;
}

.faq-list li label {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 17px;
    cursor: pointer;
    color: var(--primary-color-text);
}

.faq-list li label:hover {
    color: var(--secondary-color-text);
    transition: 0.2s ease;
}

.faq-list li label button {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    outline: none;
    color: var(--secondary-color-text);
    font-size: 15px;
}

.faq-list label {
    display: none;
}

.faq-list .content {
    padding: 0 10px;
    line-height: 26px;
    font-size: 17;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;
    color: var(--primary-color-text);
}

.active {
    max-height: 400px;
}

.content.active {
    max-height: 400px;
}

/* Preview */
.preview {
    background-color: var(--secondary-color-bg);
    padding: 60px 0;
    height: 600px;
}

.video-responsive {
    width: 100%;
    display: flex;
    justify-content: center;
}

/* About Us */
.about-us {
    padding: 60px 0;
}

.heading {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 20px auto;
}

.heading h1 {
    font-size: 2.5rem;
    color: var(--primary-color-text);
    margin-bottom: 25px;
}

.heading h1::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    display: block;
    margin: 0 auto;
}

.heading p {
    font-size: 18px;
    color: #666;
    margin-bottom: 35px;
}

.about-container {
    width: 90%;
    margin: 0 auto;
    padding: 10px 20px;
}

.about {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.about-image {
    flex: 1;
    margin-right: -200px;
    overflow: hidden;
}

.about-image img {
    max-width: 600px;
    height: auto;
    display: block;
    transition: 0.5s ease;
}

.about-content {
    flex: 1;
    width: 50%;
}

.about-content h2 {
    font-size: 27px;
    margin-bottom: 45px;
    color: var(--primary-color-text);
    text-align: center;
    position: static;
}

.about-content p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 27px;
    color: var(--primary-color-text);
    text-align: center;
    margin: 0 auto;
    max-width: 600px;
}

.about-content {
    display: inline-block;
    padding: 10px 50px;
    color: #fff;
    font-size: 19px;
    text-decoration: none;
    border-radius: 25px;
    margin-top: 15px;
    margin-bottom: 15px;
    transition: 0.3s ease;
    padding-bottom: 20px;
}

.about-content .contact-center {
    display: flex;
    justify-content: center;
}

.read-more {
    font-size: 14px;
    background: var(--secondary-color-text);
    width: 160px;
    padding: 20px;
    margin-top: 10px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition-duration: 0.3s;
    transition-property: box-shadow, transform;
}

.read-more:hover, .read-more:focus, .read-more:active {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform: scale(1.01);
}

/* Stats */
.stats {
    background-color: var(--secondary-color-bg);
    padding-top: 30px;
}

.stats .stat-header {
    font-size: 27px;
    padding-top: 40px;
    margin-bottom: 50px;
    text-align: center;
    color: var(--primary-color-text);
}

.stats-container {
    text-align: center;
    width: 100%;
    margin: 0 auto;
}

.stat-item {
    display: inline-block;
    width: 450px;
    margin-bottom: 80px;
}

.stat-item h2 {
    font-size: 2rem;
    margin-bottom: 0px;
    color: var(--primary-color-text);
}

.stat-item p {
    color: var(--primary-color-text);
}

/* Engines */
.engines {
    background-color: var(--secondary-color-bg);
    padding: 60px 0;
    margin-top: 120px;
}

.engines-title {
    text-align: center;
    margin-bottom: 60px;
    font-size: 32px;
    color: var(--primary-color-text);
}

.engines-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 110px;
}

.engine-item {
    text-align: center;
    max-width: 150px;
}

.engine-item .svg-icon {
    width: 70px;
    height: auto;
    margin-bottom: 10px;
    filter: var(--invert-svg);
}

.engine-item p {
    font-size: 16px;
    color: var(--primary-color-text);
}


/* Contact */
.contact-container {
    width: 100%;
    display: flex;
    margin-top: 120px;
    align-items: center;
    justify-content: center;
}

.contact-form {
    display: flex;
    flex-direction: column;
    padding: 2vw 4vw;
    width: 90%;
    max-width: 600px;
    border-radius: 10px;
    background-color: var(--primary-color-bg);
}

.contact-form h3 {
    color: var(--primary-color-text);
    font-weight: 10px 0;
    margin-bottom: 20px;
}

.contact-form input, form textarea {
    border: 0;
    margin: 10px 0;
    padding: 20px;
    outline: none;
    background-color: #171e30;
    color: #fff;
    resize: none;
    font-size: 16px;
}

.contact-form button {
    width: 100%;
    padding: 14px 0;
    background: transparent;
    color: var(--primary-color-text);
    font-size: 15px;
    border: 1px solid var(--secondary-color-text);
    border-radius: 6px;
    margin-top: 10px;
    cursor: pointer;
    transition: background 0.5s;
}

.contact-form button:hover {
    background: var(--secondary-color-text);
}

.contact-form p {
    color: var(--primary-color-text);
    margin-top: 10px;
}

/* Footer */
.footer {
    margin-top: 100px;
    font-weight: 400;
    padding: 40px 0;
    background-color: var(--secondary-color-bg);
    color: var(--primary-color-text);
}

.footer .social {
    text-align: center;
    padding-bottom: 25px;
    color: var (--primary-color-text);
}

.footer .social a {
    font-size: 20px;
    color: inherit;
    border: 1px solid var(--primary-color-text);
    width: 38px;
    height: 38px;
    line-height: 36px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    margin: 0 8px;
    opacity: 0.75;
}

.footer .social a:hover {
    opacity: 0.9;
}

.footer ul {
    margin-top: 0;
    padding: 0;
    list-style: none;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 0;
    text-align: center;
}

.footer ul li a {
    color: var(--primary-color-text);
    font-size: 17.5px;
    text-decoration: none;
    opacity: 0.8;
}

.footer ul li {
    display: inline-block;
    text-decoration: none;
    padding: 0 15px;
    opacity: 0.8;
}

.footer ul li a:hover {
    opacity: 1;
}

.footer .copyright {
    margin-top: 15px;
    text-align: center;
    font-size: 13px;
    color: var(--primary-color-text);
}

.footer .copyright a {
    color: var(--primary-color-text);
    text-decoration: none;
}

/* Contact Form */
.contact-form-email {
    max-width: 1200px;
    margin: 50px auto;
    background-color: var(--primary-color-text);
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 140px;
    margin-bottom: -20px;
    border-radius: 10px;
    background-color: var(--secondary-color-bg);
    color: var(--primary-color-text);
}

.email-btn {
    display: block;
    font-size: 15.5px;
    background: var(--secondary-color-text);
    width: 50%;
    margin: 0 auto;
    padding: 14px;
    margin-top: 20px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: none;
    transition-duration: 0.3s;
    transition-property: background-color, box-shadow;
}

.email-btn:hover, .email-btn:focus, .email-btn:active {
    background: #1793ff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

#telegram-button {
    margin-bottom: 20px;
}

.email-btn .email-icon {
    margin-right: 5px;
}

.telegram-icon {
    margin-right: 5px;
}

.contact-form-new {
    max-width: 1200px;
    margin: 50px auto;
    background-color: var(--primary-color-text);
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: var(--secondary-color-bg);
}

.contact-form-new h2 {
    margin-top: 0;
    margin-bottom: 5px;
    color: var(--primary-color-text);
}

.contact-form-new p {
    margin-bottom: 25px;
    font-size: 16.5px;
    color: var(--primary-color-text);
}

.contact-form-new label {
    display: block;
    margin-bottom: 10px;
    color: var(--primary-color-text);
}

.input-row {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.input-field {
    flex: 1;
    margin-right: 10px;
}

.input-field input {
    width: 100%;
}

.input-field:last-child {
    margin-right: 0;
}

.contact-form-new label {
    display: block;
    margin-bottom: 5px;
}

.contact-form-new input[type="text"],
.contact-form-new input[type="email"],
.contact-form-new select,
.contact-form-new textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 2px;
    background-color: var(--contact-color-bg);
    color: var(--primary-color-text);
    resize: none;
}

.captcha {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.submit-btn {
    display: block;
    font-size: 14px;
    background: var(--secondary-color-text);
    width: 180px;
    padding: 17px;
    margin-top: 20px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: none;
    transition-duration: 0.3s;
    transition-property: box-shadow, transform;
}

.submit-btn:hover, .submit-btn:focus, .submit-btn:active {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform: scale(1.01);
}

.submission-status {
    color: var(--primary-color-text);
    margin-top: 10px;
}

/* New Contact */
.contact-new-container {
    margin-top: 70px;
}

.contact-new-content {
    display: inline-block;
    padding: 10px 50px;
    color: var(--primary-color-text);
    font-size: 19px;
    text-decoration: none;
    border-radius: 25px;
    margin-top: 15px;
    margin-bottom: 15px;
    transition: 0.3s ease;
    padding-bottom: 20px;
}

.contact-new-content .contact-center {
    display: flex;
    justify-content: center;
}

.about-content .contact-center {
    margin-top: 20px;
    margin-bottom: -30px;
}

@media only screen and (max-width: 1320px) {
    header {
        padding: 14px 2%;
        transition: 0.2s;
    }

    .navbar a {
        padding: 5px 0;
        margin: 0px 20px;
    }

    .main-navbar {
        margin-right: 15px;
        gap: 40px;
    }

    .faq-list {
        width: 100%;
    }

    .stats-container {
        width: 50%;
    }

    .stat-item {
        width: 370px;
    }

    .contact-form-new, .contact-form-email {
        max-width: 90%;
    }
}

@media only screen and (max-width: 1100px) {
    .faq-list, .stats-container {
        width: 100%;
    }

    .stat-item {
        width: 350px;
    }

    .main-navbar {
        margin-right: 15px;
        gap: 40px;
    }
}

@media only screen and (max-width: 900px) {
    #nav-icon {
        display: block;
    }

    .navbar {
        position: absolute;
        right: 0%;
        top: -1000%;
        text-align: center;
        width: 100%;
        height: 320px;
        background: var(--secondary-color-bg);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border-radius: 10px 0 10px 0;
        transition: all 0.50s ease;
    }

    .navbar a {
        display: block;
        margin: 12px 0;
        padding: 0px 25px;
        transition: all 0.50s ease;
    }

    .navbar.open {
        top: 100%;
    }

    .main-navbar {
        margin-right: 15px;
        gap: 20px;
    }

    .home {
        margin-top: -150px;
    }

    .hero-title {
        margin-top: 120 px;
    }

    table {
        font-size: 19px;
        width: 90%;
    }

    thead {
        font-size: 18px;
    }

    td {
        padding: 25px 0px;
    }
    
    th {
        padding: 25px 5px;
    }
    
    .faq-list, .stats-container {
        width: 100%;
    }

    .stats .stat-header {
        margin-bottom: 60px;
    }

    .stat-item {
        width: 180px;
    }

    .heading {
        padding: 20 0px;
    }

    .heading h1 {
        font-size: 36px;
    }

    .heading p {
        font-size: 17px;
        margin-bottom: 0px;
    }

    .about-container {
        padding: 0px;
    }

    .about {
        padding: 20px;
    }

    .about-content {
        padding: 10px 0px;
        text-align: center;
    }

    .about-image {
        margin-right: 0px;
        margin-bottom: 20px;
    }

    .about-image img {
        max-width: 300px;
    }

    .about-content p {
        padding: 0px;
        font-stretch: 16px;
    }

    .contact-container {
        margin-top: 100px;
    }

    form h3 {
        margin-top: 15px;
    }

    .preview {
        height: 400px;
    }

    .video-responsive iframe {
        width: 380px;
        height: 215px;
    }

    .footer {
        margin-bottom: 30px;
    }

    .input-row {
        display: block;
    }

    .email-btn {
        width: 90%;
    }
}

@media (max-width: 768px) {
    .new-pricing-container {
        padding: 20px;
    }

    .new-pricing-card-container {
        flex-direction: column;
    }

    .new-pricing-card {
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
    }

    .new-pricing-card-header {
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;
    }

    .new-pricing-card-header span {
        margin-right: 8px;
    }

    .new-pricing-card-content {
        align-self: stretch;
    }

    .new-pricing-card-button {
        margin-top: 16px;
        width: 100%;
        margin-left: 0;
    }
}

@media(max-width: 574px) {
    .footer-col {
        width: 100%;
    }
}

::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-track {
    background: var(--primary-color-bg);
}

::-webkit-scrollbar-thumb {
    background: #444e63;
    border-radius: 2px;
}

.terms-container, .blog-container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;
}

.terms-container h1, .blog-container h1 {
    text-align: center;
    margin-top: 120px;
    margin-bottom: -40px;
    color: var(--primary-color-text);
}

.terms-container h2, .blog-container h2 {
    color: var(--primary-color-text);
}

.terms-content, .blog-content {
    line-height: 1.6;
}

.terms-content p, .blog-content p {
    margin-bottom: 25px;
    color: var(--primary-color-text);
}

.terms-content p b, .blog-content p b {
    color: var(--primary-color-text);
}

/* Blog */
button.icon {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
}

.blog-container {
    max-width: 50rem;
    margin: 0 auto;
    padding: 0 0.5rem 0 0.5rem;
    display: flex;
    align-items: center;
    height: 100%;
}

header.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 4.5rem;
    background-color: rgba(14, 20, 36, 0.5);
    backdrop-filter: blur(8px);
    z-index: 10;
}

header .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

header .logo {
    display: flex;
    align-items: center;
    cursor: pointer;
}

header .logo span {
    margin-left: 0.5rem;
}

.article {
    margin: 4rem 0;
}

.post-wrapper {
    width: 100%;
    margin-top: 20px;
    color: var(--primary-color-text);
}

.code {
    position: relative;
}

.code .copy-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 5;
}

.footer {
    text-align: center;
}

.blog-layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

.postlist {
    width: 45%;
    margin: auto;
    text-align: center;
}

.title {
    margin-bottom: 20px;
    margin-top: 130px;
    color: var(--primary-color-text);
}

.post-card {
    text-align: left;
    border: 1px solid #1a91ff;
    margin-bottom: 20px;
    padding: 10px 20px;
}

.notfound {
    text-align: center;
    margin-top: 20%;
}

.links {
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    color: var(--primary-color-text);
}

.links-text {
    margin-top: 20px;
    margin-bottom: 5px;
}

.post-card img {
    width: 100%;
    height: auto;
}

.links-read-more {
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    color: var(--secondary-color-text);
}

.blog-preview {
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    color: var(--primary-color-text);
}

.post-header {
    margin-top: 10%;
    margin-bottom: -15%;
    text-align: center;
}

.blog-container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0px 15px;
    overflow: hidden;
    margin-top: 120px;
}

.blog-container h1 {
    text-align: center;
    margin-top: 120px;
    margin-bottom: -40px;
    color: var(--primary-color-bg);
}

.blog-container h2 {
    color: var(--primary-color-text);
}

.blog-content {
    line-height: 1.6;
}

.blog-content p {
    margin-bottom: 25px;
    color: var(--primary-color-text);
}

.blog-content p b {
    color: var(--primary-color-text);
}

.post-header img {
    width: 55%;
    height: 80%;
    border-radius: 15px;
    max-height: 400px;
    object-fit: cover;
}

.post-header h1,
.text-below-image {
    position: absolute;
    left: 5%;
    right: 5%;
    color: var(--primary-color-text);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
    z-index: 1;
    word-wrap: break-word;
}

.image-container {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
}

.post-header h1 {
    font-size: 24px;
    top: 40%;
    color: #fff;
}

.text-below-image {
    font-size: 17px;
    bottom: 43%;
}

.image-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 15px;
}

.image-container img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    height: 50%;
}

.back-button {
    display: block;
    width: 100px;
    height: 40px;
    margin: 20px auto;
    background-color: var(--secondary-color-text);
    color: var(--primary-color-text);
    border: none;
    border-radius: 5px;
    font-family: 'YourFontName', sans-serif;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s;
    margin-top: -10px;
}

.back-button:hover {
    background: #33a1ff;
}

@media only screen and (max-width: 1320px) {
    .post-card iframe {
        width: 989px;
        height: 557px;
    }
}

@media only screen and (max-width: 1100px) {
    .postlist {
        width: 95%;
    }
}

@media only screen and (max-width: 600px) {
    .postlist {
        width: 90%;
    }

    .post-header {
        margin-top: 150px;
        margin-bottom: -100px;
    }

    .blog-container {
        margin-top: 0px;
    }

    .image-container img {
        width: 90%;
    }

    .image-container::before {
        width: 90%;
    }
}
